import React, { useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { HandlePageClick } from '../App'; // Import HandlePageClick from your App.js

const Pointshintnotik = ({ showModal, handleClose }) => {
  const navigate = useNavigate();
  const contactSectionRef = useRef(null);

  const handleProceed = (e) => {
    // Call HandlePageClick for navigation and scrolling
    HandlePageClick(e, '/notik-frame', contactSectionRef, navigate);
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><strong>How Featured Offers Work</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Earn points from Notik!</strong> 1{' '}
          <strong style={{ color: '#28a745' }}>$CUAN</strong> = 10 points. If your points are less than 10, you will still earn 1{' '}
          <strong style={{ color: '#28a745' }}>$CUAN</strong>.
        </p>
        <p>
          This means that you can start earning{' '}
          <strong style={{ color: '#28a745' }}>$CUAN</strong> even with a small number of points. So get started and complete offers to earn rewards!
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="primary"
          className="w-100 d-flex align-items-center justify-content-center"
          onClick={handleProceed} // Use the handleProceed function to close modal and navigate
        >
          <FaPlay className="me-2" />
          <strong>Proceed to Featured Offers</strong>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Pointshintnotik;
