import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button  } from 'react-bootstrap';
import { HandlePageClick } from '../App';
import './Dashboard.css';
import { API_URL } from '../App';
import { TOKENBITLABS, bitlatbs_url_surveys } from '../config';
import PlaceholderCard from './PlaceholderCard';
import { API_URL_BIT } from '../config';
import { OfferRow } from './OfferCard';
import { SurveyRow } from './SurveyCard';
import Pointshintnotik from './Pointshintnotik';
import Cookies from 'js-cookie';
import { key, decryptData } from '../App';



const Dashboard = ({ userPoints }) => {
    const navigate = useNavigate();
    const contactSectionRef = useRef(null);
    const [cpxSurveys, setCpxSurveys] = useState([]);
    const [bitlabsSurveys, setBitlabsSurveys] = useState([]);
    const [offersbitlabs, setOffersbitlabs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const clientIp_encrypted = Cookies.get('userip');
    const clientIp = decryptData(clientIp_encrypted, key);

    const [restrictionMessage, setRestrictionMessage] = useState(null);

    const [showModal, setShowModal] = useState(false);
  
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
  
    const handleProceed = () => {
      setShowModal(false);  // Close the modal
    };

    useEffect(() => {
        let hiddenStartTime = null;
    
        const handleVisibilityChange = async () => {
            if (document.hidden) {
                // Start tracking when tab becomes hidden
                hiddenStartTime = Date.now();
            } else {
                // Check duration of tab being hidden
                if (hiddenStartTime) {
                    const hiddenDuration = (Date.now() - hiddenStartTime) / 1000; // minutes
                    
                    if (hiddenDuration >= 120) {
                        // Fetch surveys again
                        setLoading(true);
                        await fetchSurveys();
                    }
                    
                    // Reset hidden start time
                    hiddenStartTime = null;
                }
            }
        };
    
        const fetchSurveys = async () => {
            const userid = localStorage.getItem('uuid');
            const useremail = localStorage.getItem('email');
    
            if (!userid) {
                setError('UUID not found in local storage');
                setLoading(false);
                return;
            }
    
            try {
                // Fetching CPX Surveys
                const cpxResponse = await axios.get(`${API_URL}/api/cpx-surveys`, {
                    params: { ext_user_id: userid, email: useremail }
                });
                setCpxSurveys(cpxResponse.data.surveys || []);
            } catch (error) {
                console.error('Failed to fetch CPX surveys:', error.message);
                setError('Failed to fetch CPX surveys');
            }
    
            /*try {
                // Fetching Bitlabs Surveys
                const bitlabsResponse = await fetch(bitlatbs_url_surveys, {
                    method: 'GET',
                    headers: {
                        'X-Api-Token': TOKENBITLABS,
                        'X-User-Id': userid,
                        'accept': 'application/json',
                    },
                });
    
                if (!bitlabsResponse.ok) {
                    throw new Error(`BitLabs Error: ${bitlabsResponse.statusText}`);
                }
    
                const bitlabsData = await bitlabsResponse.json();
    
                if (bitlabsData.data.restriction_reason) {
                    setRestrictionMessage(handleRestrictionReason(bitlabsData.data.restriction_reason));
                } else {
                    setBitlabsSurveys(bitlabsData.data.surveys || []);
                }
            } catch (error) {
                console.error('Failed to fetch BitLabs surveys:', error.message);
                setError('Failed to fetch BitLabs surveys');
            }
                */
    
            try {
                // Build the params object dynamically
                const isMobile = /android|iphone|ipad|iPod/i.test(navigator.userAgent);
                const params = {
                  in_app: isMobile ? 'true' : 'false',
                  client_user_agent: navigator.userAgent,
                  client_ip: clientIp,
                  devices: isMobile ? ['android', 'iphone', 'ipad'] : [],
                };
            
                // Make the API request
                const responseoffer = await axios.get(API_URL_BIT, {
                  headers: {
                    'User-Agent': navigator.userAgent,
                    'X-Api-Token': TOKENBITLABS,
                    'X-User-Id': userid,
                    'accept': 'application/json',
                  },
                  params: params,
                });
            
                // Set the fetched offers in the state
                setOffersbitlabs(responseoffer.data.data.offers);
            } catch (err) {
                setError('Error fetching offers');
            }
    
            setLoading(false);
        };
    
        // Initial fetch
        fetchSurveys();
    
        // Add event listener for page visibility changes
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // Cleanup event listener
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [clientIp]);



   /* const handleRestrictionReason = (reason) => {
        if (reason.not_verified) return 'The publisher account that owns this app has not been verified and therefore cannot receive surveys.';
        if (reason.using_vpn) return 'You are using a VPN and cannot access surveys.';
        if (reason.banned_until) return `You have been temporarily banned until ${new Date(reason.banned_until).toLocaleString()}. Reason: ${reason.reason}`;
        if (reason.unsupported_country) return 'Surveys are not available for your country.';
        return 'No surveys available at the moment.';
    };
    */

   

    // Combining surveys from both providers while avoiding duplicates
    const combinedSurveys = [
        ...cpxSurveys.map(survey => ({
            id: survey.id,
            image: 'https://od.lk/s/NjFfODg3MDAxNjhf/stars-4-removebg-preview.png',
            title: ``,
            points: survey.payout,
            loi: survey.loi,
            rating: survey.statistics_rating_avg,
            ratingCount: survey.statistics_rating_count,
            clickUrl: survey.href,
        })),
        ...bitlabsSurveys.map(survey => ({
            id: survey.id,
            image: survey.category.icon_url,
            title: survey.category.name,
            loi: survey.loi,
            points: survey.value,
            rating: survey.rating,
            ratingCount: survey.rating, // Assuming this is the correct usage of survey.rating
            clickUrl: survey.click_url,
        })),
        ...offersbitlabs.map(offer => ({
            id : offer.id,
            image: offer.icon_url,
            title: offer.anchor,
            points : offer.total_points,
            clickUrl: offer.clickUrl,
            events : offer.events,
            anchor : offer.anchor,
            web_to_mobile_devices : offer.web_to_mobile_devices,
            description : offer.description,
            confirmation_time : offer.confirmation_time,
            requirements : offer.requirements,
            disclaimer : offer.disclaimer,
            click_url : offer.click_url,

        }))
    ];

    // Filter to remove duplicates
    const uniqueSurveys = Array.from(new Map(combinedSurveys.map(survey => [survey.id, survey])).values());
    const uniqueoffers = Array.from(new Map(combinedSurveys.map(offer => [offer.id, offer])).values());

    return (
        <div className="page-content-4 container-dashboard">
            <div className="container mt-4">
                <div className="card shadow-sm">
                    <div className="card-body">
                        <h1 className="card-title"><strong>Dashboard</strong></h1>
                        <p className="card-text">Here are some surveys and offers you can complete to earn <strong style={{ color: '#28a745' }}>$CUAN</strong>.</p>

                        {loading ? (
                            <>
                          <div className="row flex-nowrap overflow-auto mb-3">
                              {Array.from({ length: 6 }).map((_, index) => (
                                  <PlaceholderCard key={index} />
                                     ))}
                                        </div>
                                          <div className="row flex-nowrap overflow-auto">
                                           {Array.from({ length: 6 }).map((_, index) => (
                                             <PlaceholderCard key={index + 6} />
                                                   ))}
                                                  </div>
                                                  <div className="row flex-nowrap overflow-auto">
                                           {Array.from({ length: 6 }).map((_, index) => (
                                             <PlaceholderCard key={index + 6} />
                                                   ))}
                                                  </div>
                                                          </>
                        ) : error ? (
                            <div className="text-center text-danger">{error}</div>
                        ) : restrictionMessage ? (
                            <div className="text-center text-warning">{restrictionMessage}</div>
                        ) : (
                            <>
                                <OfferRow title="Offer Games" offers={uniqueoffers.filter(offer => offersbitlabs.some(ofr => ofr.id === offer.id))} />
                                <SurveyRow title="Survey providers" surveys={uniqueSurveys.filter(s => s.title === '')} />
                                <SurveyRow title="Research Partners" surveys={uniqueSurveys.filter(survey => bitlabsSurveys.some(bls => bls.id === survey.id))}  />
                                
                            </>
                        )}
                        <div className="d-flex gap-3 mt-3 button-learn-more">
                            <Button onClick={(e) => HandlePageClick(e, '/tasks', contactSectionRef, navigate)}>View All Tasks</Button>
                            <Button onClick={(e) => HandlePageClick(e, '/survey-cpx', contactSectionRef, navigate)}>View all Surveys</Button>
                            <Button onClick={handleOpenModal}>Featured Offers</Button>
                               <Pointshintnotik showModal={showModal} handleClose={handleCloseModal} handleProceed={handleProceed} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
