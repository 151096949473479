// src/env.js
import React, {useEffect} from 'react';

export const APP_KEY_POLLFISH = "13fa241a-95e6-407a-a741-c621f763c28f";
export const TOKENBITLABS = "d912310f-627a-4d0b-b698-c84bbbe06fca";
export const API_URL_BIT = 'https://api.bitlabs.ai/v2/client/offers';
export const bitlatbs_url_surveys = 'https://api.bitlabs.ai/v2/client/surveys';
export const APPID = "18483";
export const SECURE_H = "Fi8IGbyceVTM9mbob09f1BvrZiB5IYiL";
export const PK_POLLFISH = "fe451002-ded6-4cc4-b0f6-c0d9c3ca7a0f";
export const REQUEST_UUID = "5ba3b063-046f-4cb6-b09d-c4f7ebafb1ba";
export const API_URL_MAIN = 'https://server-x-rho.vercel.app,http://localhost:5001,https://apifreecuan.site';
export const web_client = 'https://freecuan.site,http://localhost:3000,https://localhost';
export const PLACEMENT_KEY = 'fe451002-ded6-4cc4-b0f6-c0d9c3ca7a0f';
export const API_KEY_FIREBASE = 'AIzaSyCDlD1_6XwXolp74COqR06WhFjRox69jB0';
export const AUTH_DOMAIN_FIREBASE = "rahud-56dd4.firebaseapp.com";
export const PROJECT_ID_FIREBASE = "rahud-56dd4";
export const STORAGEBUCKET_FIREBASE = "rahud-56dd4.appspot.com";
export const MESSAGING_SENDERID_FIREBASE = "175913057645";
export const APPID_FIREBASE = "1:175913057645:web:b86aa57794cb18b93bee64";
export const MEASUREMENTID_FIREBASE = "G-1PMMKPZ5FH";
export const bajuhitam ="DDWSDOB9JJHVpqaAYtQYnYqB8XMAEkLT";
export const APIKEY_NOTIK = "vVQQAX9ktg1O1u7e0EioeA4ngIGkk8yn";
export const PUBID_NOTIK = "0J7K9l";
export const APPID_NOTIK = "noRNt5Vw1D";
export const scrollToTop = () => {
                    window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                     });
                      };
export const useRedirect = (redirectPath) => {
                     useEffect(() => {
                       const redirect = () => {
                     window.location.assign(redirectPath);
                     };

                         window.addEventListener('redirectEvent', redirect);

                              return () => {
                              window.removeEventListener('redirectEvent', redirect);
                              };
                        }, [redirectPath]);
                          };



            

// For debugging purposes
const Config = () => {
  return (
    <div>
      <h1>Welcome to my App</h1>
    </div>
  );
};

export default Config;
